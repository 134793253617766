/* content-registration start --------------------*/
.content-registration {
    height: 100%;
    width: 100%;
    bottom: -10000px;
    position: fixed;
    z-index: 99;
    transition: bottom 0.45s;
}

.show-registration {
    bottom: 0;
}

.hide-registration {
    bottom: -10000px;
}

.overly-bg,
.show-registration .text-x {
    display: none;
}

.show-registration .overly-bg {
    position: fixed;
    z-index: 9;
    background: rgba(0, 0, 0, 0.411);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
}

.show-registration .text-x {
    position: fixed;
    color: #ffd117;
    left: 40px;
    border: 1px solid;
    border-radius: 50%;
    padding: 1px 3px;
    top: 40px;
    z-index: 999999;
    background: #3232325e;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-registration .content-box {
    position: fixed;
    z-index: 999;
    border-radius: 24px 24px 0px 0px;
    background: #d92366;
    background-size: cover;
    overflow-y: hidden;
    padding: 15px;
    width: 430px;
    max-width: 100%;
    height: auto;
    bottom: -1000px;
    left: 0;
    right: 0;
    transition: bottom 0.5s;
    box-shadow: -5px 0 74px rgba(0, 0, 0, 0.8);
    margin: auto;
    overflow: auto;
}

.content-registration.show-registration .content-box {
    bottom: 0;
}
.content-box h2 {
    font-size: 1.5rem;
}
.content-registration .login-form-box {
    min-height: 170px;
}
.content-registration .form-check-label {
    color: #fff;
    margin: 3px 8px;
    font-weight: 300;
}
.content-registration .form-check-input {
    height: 1.5em;
    width: 1.5em;
    border: 1px solid #ffd117 !important;
    background: transparent;
}
.content-registration .form-check-input:checked {
    background: url(../../assets/icons/checkobx.svg) no-repeat center center;
    background-size: 24px 24px;
    border: 1px solid transparent !important;
}
.disable-btn {
    background: #f08b37 !important;
    border: #f08b37 !important;
    cursor: not-allowed;
}
.steps-dash {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem;
    padding-top: 2rem;
}
.steps-dash .step {
    height: 4px;
    width: 49%;
    border-radius: 4px;
    background: #e46594;
}
.steps-dash .step.active {
    background: #ffd117;
}
.back-next-btns {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 30px;
}
.video-uploaded-box {
    background: #ffffff;
    border-radius: 20px;
    overflow: hidden;
}
.video-uploaded-box .video-box {
    height: 150px;
}
.video-uploaded-box .video-content .progress {
    height: 4px;
    margin-top: 6px;
}

/* media or mobile responsive start --------------------------------*/
@media screen and (max-width: 768px) {
    .swiper-home .swiper-slide {
        width: calc(50% - 15px);
    }
    .video-box .video-player {
        width: 100% !important;
        height: 100px !important;
    }
    .video-box {
        border: 6px solid;
        border-radius: 12px;
    }
    .video-box .VideoBtnIcon {
        height: 30px;
        width: 30px;
    }
}
@media screen and (max-width: 992px) {
    .content-registration .text-x {
        right: auto;
        left: 15px;
        top: 15px;
        border: 0;
        background: transparent;
    }
}

/* my team page start */
.team-main-box{
    position: relative;
    z-index: 1;
    
  }
  .team-main-box::after{
    content: " ";
    position: absolute;
    top: 5px;
    left: 50%;
    width: 2px;
    height: 100%;
    border: 2px dotted #638A98;
    background: transparent;
    z-index: -1;
    max-height: calc(100% - 10px);
  }
  .team-single-box{
    position: relative;
    border-radius: 12px;
    border: 1px solid #073748;
    background: #032633;
    padding: 16px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 16px;
    margin-bottom: 20px;
  }
  .team-single-box .team-img-rounded{
    width: 5rem;
    height: 5rem;
    background: url(../../assets/icons/user-profile.svg) no-repeat center center;
    background-size: cover;
    overflow: hidden;
    border-radius: 50%;
  }
  .team-single-box .team-name{
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
  }
  .team-single-box .team-position{
    color: #B5C2C7;
    font-size: 16px;
  }
  /* my team page end */
/* faq-accordion start */
.faq-accordion{
    background: #042430;
    border: 0;
  }
  .faq-accordion .accordion-item{
    background: transparent;
    border: 0;
  }
  .faq-accordion  .accordion-header button{
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    background: transparent;
    padding-top: 64px;
  }
  .faq-accordion  .accordion-header .number{
    position: absolute;
    left: 18px;
    top: 16px;
    color: #405962;
    font-size: 32px;
  
  }
  .faq-accordion .accordion-button:not(.collapsed){
    background: #0F485E;
    border: 0;
    box-shadow: none;
  }
  .faq-accordion .accordion-header button:focus{
    box-shadow: none;
  }
  .faq-accordion  .accordion-body{
    background: #0F485E;
    color: #B4BDC1;
    font-size: 16px;
    border-bottom: 1px solid #0F485E;
  }
  .accordion-button::after, .accordion-button:not(.collapsed)::after{
    content: "";
    background: #FFD117;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-image: url(../../assets/icons/plus.svg);
    background-repeat: no-repeat;
    background-position: center;
     padding: 3px;
  }
  .accordion-button:not(.collapsed)::after{
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  #accordionJudges .accordion-header button{
    padding-top: 20px;
  }
  /* faq-accordion start */
body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    padding-bottom: 5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Inter', sans-serif;
}
a {
    text-decoration: none;
}
button:active {
    outline: 0;
    box-shadow: none;
}
.text-yellow {
    color: #ffd117 !important;
}
.bg-yellow {
    background: #ffd117 !important;
}
.bg-green {
    background: #0bae4c !important;
}
.text-dark-yellow {
    color: #f08b37 !important;
}
.text-dark-red {
    color: #dc3434 !important;
}
.img-box {
    overflow: hidden;
}
.cursor-pointer,
.cursor-pointer:hover {
    cursor: pointer;
}

/* font-size */
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px !important;
}

/* height */
.vh-76 {
    height: 76vh;
}

/* common style end */
body {
    position: relative;
    background: #042430;
}
body::after {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    min-height: 100vh;
    background: url(../../assets/images/net-bg.svg) no-repeat center center;
    background-size: cover;
}

.btn-theme {
    display: inline-block;
    width: 157.5px;
    border-radius: 66px;
    padding: 18px 10px 18px 10px;
    gap: 10px;
    color: #d92366;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    box-shadow: -1px 4px 0px 0px #fff;
    text-decoration: none;
    text-decoration: none;
}
.btn-theme.btn-primary {
    background: #042430;
    border: 2px solid #ffd117;
}
.btn-theme.btn-secondary {
    background: #ffd117;
    border: 2px solid #ffd117;
}

/* custom form design statrt------------------ */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
.form-control {
    color: #fff !important;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    padding: 8px 16px;
    border-radius: 12px !important;
}

.form-control fieldset {
    border: 0;
}
.form-control .MuiSelect-iconOutlined {
    display: none;
}
.MuiDialog-paperScrollPaper {
    min-width: 300px;
}
.MuiDialog-paperScrollPaper .MuiButton-text {
    font-family: 'Inter', sans-serif;
    display: flex;
    width: 100%;
    padding: 18px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 66px;
    background: #d92366;
    box-shadow: -1px 4px 0px 0px #fff;
    color: #ffd117;
}
.MuiDialog-paperScrollPaper .MuiFormLabel-colorPrimary {
    font-weight: 700;
    margin-bottom: 12px;
    color: #042633;
}

.form-control:focus,
.form-check-input:focus,
.form-select:focus,
.MuiInputBase-colorPrimary:focus,
select:focus {
    box-shadow: none !important;
    border: 1px solid #f08b37 !important;
    outline: 0 !important;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after,
.form-select {
    background: transparent !important;
}
.form-floating .participants-multi-box {
    max-width: 100%;
    overflow: hidden;
    background-color: red;
}
.form-floating .participants-multi-box .MuiFormControl-root {
    padding: 0;
    border-radius: 12px;
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
}
.form-floating .participants-multi-box .MuiSelect-select {
    padding-top: 25px;
}
.form-floating > label,
.MuiInputLabel-formControl {
    color: #f4bdd1 !important;
    font-size: 14px;
    font-family: 'Inter', sans-serif !important;
}
.MuiInputLabel-formControl.Mui-focused {
    position: absolute;
    top: 10px;
}
.form-group > .form-control,
.form-control:-webkit-autofill:hover,
.form-floating > .form-control,
.form-floating > .form-select,
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 50px transparent inset !important;
    background-color: transparent !important;
    background-clip: text;
    -webkit-text-fill-color: #fff !important;
}
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 50px transparent inset !important;
    background-color: transparent !important;
    background-clip: text;
    -webkit-text-fill-color: #fff !important;
}
select option {
    background-color: #fff !important;
    color: #333 !important;
}
.arrow-select {
    position: absolute;
    right: 5px;
    top: 1em;
}
.input-with-icon {
    position: relative;
}
.input-with-icon img,
.form-group img {
    position: absolute;
    top: 30%;
    left: 15px;
}
.input-with-icon .form-control {
    padding-left: 50px;
}
.input-with-icon label {
    padding-left: 55px;
}
.input-with-search-icon {
    position: relative;
}
.input-with-search-icon img,
.form-group img {
    position: absolute;
    top: 30%;
    right: 15px;
}
.input-with-search-icon .form-control {
    padding-right: 50px;
}
.input-with-search-icon label {
    padding-right: 55px;
}
.select-arrow img {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 30%;
}
.video-upload-btn .form-control {
    opacity: 0;
    height: 0;
}
.video-upload-btn label {
    width: 100%;
    height: 150px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 16px;
    border: 1px dashed #ffd117;
    background: #dd3975;
    color: #ffffff;
}
.btn-share-on-watch {
    position: absolute;
    bottom: 15px;
    right: 15px;
}
.btn-share {
    display: flex;
    padding: 2px 10px;
    align-items: center;
    color: #ffffff;
    gap: 10px;
    border-radius: 68px;
    background: #1a7192;
}
.btn-share:hover {
    background: #ffd117;
    color: #ffffff;
}
.page-title {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}
/* custom form design end -        ------------------ */
.subpage-main-box {
    margin-bottom: 2.5rem;
}
.subpage-single-box .content-bottom {
    padding-top: 2rem;
    color: #ffffff;
}
.subpage-single-box .content-bottom h1 {
    color: #ffffff;
    font-size: 1.5rem;
}
.video-box-best-of{
    display: flex;
    height: 400px;
}
/* footer style :start */

.footer-theme {
    background: #042633;
    position: fixed;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    margin-top: 50px;
    z-index: 9;
}
.social-icons a {
    color: #ffffff;
}

/* social share */
.copy {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
}

@media screen and (max-width: 768px){
    body {
        padding-bottom: 8rem;
    }
}

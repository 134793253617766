.big-video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    border-radius: 10px;
}
.big-video-container .react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.small-video-bg {
    background-color: #031a22;
    border-radius: 12px;
}
@media screen and (max-width: 992px) {
    .small-video-bg {
        background-color: transparent;
    }
}
.small-video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    border-radius: 5px;
}
.small-video-container .react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.small-video-container .videoBtnIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 9999;
}

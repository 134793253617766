
.merchandise-single-box{
    position: relative;
    border-radius: 12px;
    padding: 16px;
    display: block;
  }
  .merchandise-single-box .merchandise-img-rounded{

    /* background: url(../images/merchandise-bg.svg) no-repeat center center;
    background-size: cover; */
    /* height: 125px; */
    overflow: hidden;
    border-radius: 16px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .merchandise-single-box .merchandise-name{
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    text-transform: capitalize;
  }

  .merchandise-single-box .merchandise span{
    border-radius: 5%;
    font-size: 11px;
    padding: 5px 7px;
    margin-top: 10px;
    display: inline-flex;
    text-transform: uppercase;
    margin-right: 10px;
  }
  .merchandise-single-box .merchandise span.category{
    background-color: #042BA8;
    color: #fff;
  }
  .merchandise-single-box .merchandise span.gender{
    background-color: #dc3434;
    color: #fff;
  }
  .merchandise-single-box .merchandise p{
    color: #FFF;
    font-size: 12px;
  }
  .merchandise-single-box .merchandise .buy-now{
    background-color: #ffd117;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 9px 13px;
    border-radius: 7px;
    box-shadow: -1px 4px 0 0 #fff;
  }
  /* merchandises page end */
.home-slider-sec {
    margin-top: 50px;
}

.home-slider-box-sec {
    padding-bottom: 1rem;
}
.close-login-big-dash {
    display: inline-block;
    background: #ffffff;
    width: 50px;
    height: 3px;
    vertical-align: top;
}
.swiper-home {
    width: 100%;
    height: 100%;
}
.swiper-home .video-container {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
}
.swiper-home .video-container .react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.swiper-home .swiper-wrapper {
    overflow: visible;
    padding-bottom: 50px;
}
.swiper-home .swiper-slide {
    width: calc(25% - 15px);
}
.swiper-home .swiper-pagination-bullet {
    border: 1px solid #fff;
    width: 12px;
    height: 12px;
    background: #3f3f3f;
}
.swiper-home .swiper-pagination-bullet-active {
    width: 24px;
    border-radius: 6px;
    background: #e22167;
}
.swiper-home .swiper-pagination {
    bottom: 10px;
}
.video-box {
    position: relative;
    border: 10px solid;
    border-radius: 20px;
    overflow: hidden;
}

.video-box:nth-child(2n + 2) {
    border-color: #6cc2f4;
}
.video-box:nth-child(2n + 3) {
    border-color: #ffd117;
}
.video-box:nth-child(3n + 2) {
    border-color: #d92366;
}
.video-box .video-player {
    width: 100% !important;
    height: 150px !important;
}
.video-box .VideoBtnIcon {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 9999;
}

@media screen and (max-width: 992px) {
    .home-page-main .middle-sec {
        background: url(../../assets/images/home-wave.svg) center center no-repeat;
        background-size: cover;
    }
    .home-slider-sec {
        margin-top: -10px;
    }
    .video-box .VideoBtnIcon {
        top: 30%;
    }
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #ffd117;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-container img {
    animation: bounce .4s ease infinite alternate;
    -moz-animation: bounce .4s ease infinite alternate;
    -webkit-animation: bounce .4s ease infinite alternate;
}

@keyframes bounce {
    0% {
        text-shadow:
            0 5px 0 #ccc,
            0 2px 3px rgba(0, 0, 0, 1);
    }

    100% {
        transform: translateY(-20px);
        text-shadow: 0 50px 0 #000,
            0 0px 20px rgba(0, 0, 0, .8);
    }
}
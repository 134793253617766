/* header  .offcanvas.offcanvas-start  start */
header {
    border-bottom: 1px solid #053243;
    background: #042430;
}
header .offcanvas.offcanvas-start {
    z-index: 9999;
    width: 300px;
    padding: 48px 24px 10px 24px;
    background: #042430;
}
header .offcanvas.offcanvas-start .btn-close {
    background-image: url(../../assets/icons/x-white.svg);
    background-size: 35px;
}
header .offcanvas.offcanvas-start .offcanvas-title {
    color: #fff;
    text-align: start;
    font-family: Inter;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    height: 100%;
    text-wrap: nowrap;
}
header .offcanvas.offcanvas-start .profile-img {
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 50%;
}
header .offcanvas.offcanvas-start .offcanvas-header,
header .offcanvas.offcanvas-start .offcanvas-body {
    padding: 15px 0px 32px 0;
}
header .offcanvas.offcanvas-start .offcanvas-footer {
    border-top: 1px solid #094257;
}
header .offcanvas.offcanvas-start .offcanvas-header {
    border-bottom: 1px solid #094257;
}
header .offcanvas.offcanvas-start .nav .nav-link {
    color: #fff;
    font-family: Inter;
    font-weight: 400;
    line-height: 20px;
    padding-left: 0;
    margin-top: 12px;
}
/* nav-desktop :start */
.nav-desktop .nav-link, .dropdown-toggle.show.nav-link {
    color: #9ba7ac;
}
.nav-desktop .nav-link.active {
    color: #fff;
    border-radius: 4px;
    border-bottom: 4px solid #ffd117;
}
.basic-nav-dropdown .dropdown-menu{
    padding: 10px;
    border-radius: 0px;
    background: #073f55;
    box-shadow: 0px 11px 44px 0px rgba(15, 111, 148, 0.2);
}
.basic-nav-dropdown .dropdown-menu a{
    background: transparent;
    color: #9ba7ac;
}
@media screen and (max-width: 991px) {
    .basic-nav-dropdown .dropdown-menu.show{
        position: relative !important;
        top: 0 !important;
        transform: none !important;
    }
    .nav-pills .nav-link.active,  .nav-pills .show>.nav-link{
        background: transparent;
    }
}

@media screen and (min-width: 991px) {
    .dropdown-profile .dropdown-menu {
        padding: 16px;
        border-radius: 16px;
        background: #073f55;
        box-shadow: 0px 11px 44px 0px rgba(15, 111, 148, 0.2);
    }
    .dropdown-profile .dropdown-menu[data-bs-popper] {
        right: 0;
        top: 72px;
        width: 230px;
        left: auto;
    }
    .dropdown-profile .dropdown-menu .dropdown-item {
        color: #9ba7ac;
        font-family: Inter;
        padding: 0;
        padding-bottom: 7px;
        padding-top: 7px;
    }
    .dropdown-profile .dropdown-menu .dropdown-item:hover {
        background: transparent;
        color: #ffffff;
    }
    .dropdown-profile .dropdown-item .dropdown-divider {
        border: 1px solid #0d6283;
    }
    .dropdown-profile .dropdown-menu::before {
        content: '';
        position: absolute;
        top: -20px;
        right: 20px;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid #073f55;
    }

 
}


/* header .offcanvas.offcanvas-start  end */

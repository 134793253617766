
.sponsor-single-box{
    position: relative;
    border-radius: 12px;
    padding: 16px;
    display: block;
  }
  .sponsor-single-box .sponsor-img-rounded{

    background: url(../images/merchandise-bg.svg) no-repeat center center;
    background-size: cover;
    height: 160px;
    overflow: hidden;
    border-radius: 16px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sponsor-single-box .sponsor-name{
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    line-height: 1.5;
  }
  /* sponsor page end */